var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      { staticClass: "h-full flex justify-center loader items-center" },
      [
        _c("div", [
          _c(
            "div",
            { staticClass: "h-full w-full flex justify-center items-center" },
            [
              _c("div", { staticClass: "lds-ring" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ]),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }